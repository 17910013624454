<template>
  <div class="mt--8 pb-5 container" v-if="setting">
    <h1 class="text-center text-white mb-5">EDIT ACTIVE RULE</h1>
    <b-form v-if="setting">
      <div class="card bg-secondary border-0">
        <b-row class="setting-edit">
          <b-col lg="10" class="mx-auto">
            <b-row class="border-bottom sticky-top bg-white page-header">
              <div class="d-flex align-items-center">
                <b-link @click="goToPrev()">
                  <font-awesome-icon icon="arrow-left-long" />
                </b-link>
                <h5 class="text-uppercase p-0 my-3 mx-2 flex-grow-1">Back</h5>
                <b-button @click.prevent="saveSettings">Update</b-button>
              </div>
            </b-row>

            <h3 class="my-3">Destination IP</h3>
            <b-row>
              <b-col>
                <b-form-group label="" label-for="destinationIP" class="mb-3">
                  <b-form-input
                    id="destinationIP"
                    name="destinationIP"
                    type="text"
                    class="w-100"
                    v-model="setting.destinationPrefix"
                    placeholder="0.0.0.0"
                    required />
                </b-form-group>
              </b-col>
            </b-row>
            <h3 class="my-3">Protocol Blocks</h3>
            <b-row>
              <b-col lg="6">
                <b-form-group label="" label-for="protocolBlocks" class="mb-3">
                  <b-row>
                    <b-col lg="6">
                      <p style="font-size: large">Block all</p>
                    </b-col>
                    <b-col lg="6">
                      <b-form-checkbox
                        switch
                        name="check-button"
                        @change="onChangeProtocol('all')"
                        v-model="setting.protocolAll" />
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="!setting.protocolAll">
              <b-col lg="6">
                <b-form-group label="" label-for="protocolBlocks" class="mb-3">
                  <b-row>
                    <b-col lg="6">
                      <p style="font-size: large">Block all UDP</p>
                    </b-col>
                    <b-col lg="6">
                      <b-form-checkbox
                        switch
                        name="check-button"
                        @change="onChangeProtocol('udp')"
                        v-model="setting.udpAll" />
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="!setting.protocolAll">
              <b-col lg="6">
                <b-form-group label="" label-for="protocolBlocks" class="mb-3">
                  <b-row>
                    <b-col lg="6">
                      <p style="font-size: large">Block all TCP</p>
                    </b-col>
                    <b-col lg="6">
                      <b-form-checkbox
                        switch
                        name="check-button"
                        @change="onChangeProtocol('tcp')"
                        v-model="setting.tcpAll" />
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row  v-if="!setting.protocolAll">
              <b-col lg="6">
                <b-form-group label="" label-for="protocolBlocks" class="mb-3">
                  <b-row>
                    <b-col lg="6">
                      <p style="font-size: large">Block all ICMP</p>
                    </b-col>
                    <b-col lg="6">
                      <b-form-checkbox
                        switch
                        name="check-button"
                        v-model="setting.icmpAll" />
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row v-if="!setting?.protocolAll">
              <b-col>
                <b-form-group
                  id="extraProtocols"
                  label="Block Specific Protocols"
                  label-for="extraProtocols">
                  <b-form-input
                    id="extraProtocols"
                    name="extraProtocols"
                    type="text"
                    class="w-100"
                    v-model="setting.extraProtocols"
                    placeholder="comma-seperated numbers"
                    required></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <h3 class="my-3" v-if="(!setting.tcpAll || !setting.udpAll) && !setting.protocolAll">Source Ports</h3>
            <b-row v-if="!setting.udpAll && !setting.protocolAll">
              <b-col lg="6">
                <b-form-group label="" label-for="protocolBlocks" class="mb-3">
                  <b-row>
                    <b-col>
                      <p style="font-size: large">Block specific source UDP</p>
                    </b-col>
                    <b-col>
                      <b-form-checkbox
                        switch
                        name="check-button"
                        v-model="setting.udpSource" />
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="setting.udpSource && !setting.udpAll && !setting.protocolAll">
              <b-col>
                <b-form-group
                  id="sourceUDPPortToBlock"
                  label="Source UDP Ports to block"
                  label-for="sourceUDPPortToBlock">
                  <b-form-input
                    id="sourceUDPPortToBlock"
                    name="sourceUDPPortToBlock"
                    type="text"
                    class="w-100"
                    v-model="setting.udpSourcePorts"
                    placeholder="comma-seperated numbers"
                    required></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="!setting.tcpAll && !setting.protocolAll">
              <b-col lg="6">
                <b-form-group label="" label-for="protocolBlocks" class="mb-3">
                  <b-row>
                    <b-col>
                      <p style="font-size: large">Block specific source TCP</p>
                    </b-col>
                    <b-col>
                      <b-form-checkbox
                        switch
                        name="check-button"
                        v-model="setting.tcpSource" />
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
            </b-row>
            <b-col v-if="setting.tcpSource  && !setting.tcpAll && !setting.protocolAll">
              <b-form-group
                id="dTCPb"
                label="Source TCP Ports to Block"
                label-for="dTCPb">
                <b-form-input
                  id="dTCPb"
                  name="dTCPb"
                  type="text"
                  class="w-100"
                  v-model="setting.tcpSourcePorts"
                  placeholder="comma-seperated numbers"
                  required></b-form-input>
              </b-form-group>
            </b-col>

            <h3 class="my-3" v-if="!setting.protocolAll & (!setting.tcpAll || !setting.udpAll)">Destination Ports</h3>
            <b-row v-if="!setting.udpAll && !setting.protocolAll">
              <b-col lg="6">
                <b-form-group label="" label-for="protocolBlocks" class="mb-3">
                  <b-row>
                    <b-col>
                      <p style="font-size: large">
                        Block specific Destination UDP
                      </p>
                    </b-col>
                    <b-col>
                      <b-form-checkbox
                        switch
                        name="check-button"
                        v-model="setting.udpDest" />
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="setting.udpDest && !setting.udpAll && !setting.protocolAll">
              <b-col>
                <b-form-group
                  id="sourceUDPPortToBlock"
                  label="Destination UDP Ports to Block"
                  label-for="sourceUDPPortToBlock">
                  <b-form-input
                    id="sourceUDPPortToBlock"
                    name="sourceUDPPortToBlock"
                    type="text"
                    class="w-100"
                    v-model="setting.udpDestPorts"
                    placeholder="comma-seperated numbers"
                    required></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="!setting.tcpAll && !setting.protocolAll">
              <b-col lg="6">
                <b-form-group label="" label-for="protocolBlocks" class="mb-3">
                  <b-row>
                    <b-col>
                      <p style="font-size: large">
                        Block specific Destination TCP
                      </p>
                    </b-col>
                    <b-col>
                      <b-form-checkbox
                        switch
                        name="check-button"
                        v-model="setting.tcpDest" />
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
            </b-row>
            <b-col v-if="setting.tcpDest && !setting.tcpAll && !setting.protocolAll">
              <b-form-group
                id="dTCPb"
                label="Destination TCP Ports to Block"
                label-for="dTCPb">
                <b-form-input
                  id="dTCPb"
                  name="dTCPb"
                  type="text"
                  class="w-100"
                  v-model="setting.tcpDestPorts"
                  placeholder="comma-seperated numbers"
                  required></b-form-input>
              </b-form-group>
            </b-col>

            <h3 class="mt-5 mb-2">Packet Lengths</h3>
            <b-col>
              <b-col lg="6">
                <b-form-group label="" label-for="protocolBlocks" class="mb-3">
                  <b-row>
                    <b-col>
                      <p style="font-size: large">
                        Block specific packet length
                      </p>
                    </b-col>
                    <b-col>
                      <b-form-checkbox
                        switch
                        name="check-button"
                        @change="onChangeRatePacketLimit('packet')"
                        v-model="setting.packetLength" />
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>

              <b-col v-if="setting.packetLength">
                <b-form-group
                  id="pLengthTB"
                  label="Packet lengths to block"
                  label-for="pLengthTB">
                  <b-form-input
                    id="pLengthTB"
                    name="pLengthTB"
                    type="text"
                    class="w-100"
                    v-model="setting.packetLengths"
                    placeholder="comma-seperated numbers"
                    required></b-form-input>
                </b-form-group>
              </b-col>
            </b-col>

             <h3 class="mt-5 mb-2">Rate Limiter</h3>
            <b-col>
              <b-col lg="6">
                <b-form-group label="" label-for="enableRateLimiter" class="mb-3">
                  <b-row>
                    <b-col>
                      <p style="font-size: large">
                        Enable Rate Limiter
                      </p>
                    </b-col>
                    <b-col>
                      <b-form-checkbox
                        switch
                        name="check-button"
                        @change="onChangeRatePacketLimit('rate')"
                        v-model="setting.enableRateLimiter" />
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>

              <b-col v-if="setting.enableRateLimiter && !setting.packetLength">
                <b-form-group
                  id="rateLimitNumberOfRequestsPerMinute"
                  label="Number of Requests Per Minute"
                  label-for="rateLimitNumberOfRequestsPerMinute">
                  <b-form-input
                    id="rateLimitNumberOfRequestsPerMinute"
                    name="rateLimitNumberOfRequestsPerMinute"
                    type="number"
                    class="w-100"
                    v-model="setting.rateLimitNumberOfRequestsPerMinute"
                    placeholder="Number"
                    required></b-form-input>
                </b-form-group>
              </b-col>
            </b-col>

            <h3 class="mt-5">Time Limit</h3>
            <b-col class="pb-3">
              <b-form-group
                id="timeLimit"
                label="Time in seconds to auto remove rule after attack is no longer detected"
                label-for="timeLimit">
                <b-form-input
                  id="timeLimit"
                  name="timeLimit"
                  type="number"
                  class="w-100"
                  disabled
                  v-model="setting.timeLimit"
                  placeholder="1 - 1000000"
                  required></b-form-input>
              </b-form-group>
            </b-col>

            <h3 class="mt-5">History</h3>
            <b-col lg="6" v-if="setting.createdAt">
              <p><strong>
                Added:</strong>
                {{ getDateString(new Date(setting.createdAt).toISOString()) }}
              </p>
            </b-col>
            <b-col lg="6" v-if="setting.updatedAt">
              <p>
                <strong>Updated:</strong>
                {{ getDateString(new Date(setting.updatedAt).toISOString()) }}
              </p>
            </b-col>
            <b-col lg="6" v-if="setting.staticActionId > 0">
              <p>
                <strong>Static Action ID:</strong>
                {{ setting.staticActionId }} <a :href="'/static-actions/edit/'+setting.staticActionId">View Static Action</a>
              </p>
            </b-col>
          </b-col>
        </b-row>
      </div>
    </b-form>
  </div>
</template>
<script>
  import ActiveRuleService from "../../services/active-rule.service";
  import "vue3-toastify/dist/index.css";
  import { notify } from "../../helpers/index";
  import { DateTime } from "luxon";

  export default {
    name: "ActiveRule",

    data: function () {
      return {
        setting: [],
        prevPage: 0
      };
    },
    computed: {
      currentUser() {
        return this.$store.state.auth.user;
      },
      activeRuleID() {
        return this.$route.params.id;
      },
    },
    mounted() {
      this.getAllActiveRules();
      this.$title = "Active Rules";
      this.prevPage = window?.location?.search?.split("?page=")[1] || 0;
    },
    methods: {
      goToPrev(){
        window.history.pushState({page: 1}, "Active Rules", "/active-rules?page=" + this.prevPage);

      },
      onChangeProtocol(protocol){
        if(protocol =='udp'){
          this.setting.sourceUDPPorts = this.setting.udpDest = !this.setting.udpAll;
        }
        if(protocol == 'tcp'){
          this.setting.sourceTCPPorts = this.setting.tcpDest = !this.setting.tcpAll;
        }
        if(protocol == 'all' && this.setting.protocolAll == true){
          this.setting.tcpAll = this.setting.udpAll = this.icmpAll = !this.setting.protocolAll;
          this.setting.sourceUDPPorts = this.setting.udpDest = this.setting.sourceTCPPorts = this.setting.tcpDest = !this.setting.protocolAll;
        }
      },
      onChangeRatePacketLimit(type) {
        const packet = this.setting.packetLength;
        const rateLimit = this.setting.enableRateLimiter;
        if (packet == rateLimit) {
          if (type == 'packet') this.setting.enableRateLimiter = !packet;
          if (type == 'rate') this.setting.packetLength = !rateLimit;
        }
      },
      async getAllActiveRules(page = 0) {
        const url = `active-rules/${this.activeRuleID}`;
        try {
          const reply = await ActiveRuleService.getAllActiveRules(url, {
            perPage: 10,
            currentPage: page
          });
          if (reply.status == 200 && reply.data) {
            this.setting = reply.data;
          }
        }
        catch {
          notify("Active Rule not found", "error");
          this.$router.go(-1);
        }
      },
      getDateString(date) {
        return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_HUGE);
      },

      async saveSettings() {
        const url = `active-rules/${this.activeRuleID}`;
        try{
          const reply = await ActiveRuleService.updateActiveRules(
            url,
            JSON.stringify(this.setting)
          );

          if (reply.status == 200 && reply.data) {
            this.setting = reply.data;
            notify("Active Rule updated successfully");
            this.getAllActiveRules(this.prevPage);
          }
        }
        catch(error) {
          var text = 'Active rule update failed failed'
          if(error.response.data.message == "Error 1062: Duplicate entry 'UDP' for key 'packet_thresholds.destination_prefix'"){
            text = text + "due to conflicting Destination Prefix"
          }
          notify(text, 'error')
        }
      },
    },
  };
</script>
<style scoped>
  .bg-secondary {
    background-color: #f7fafc !important;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    scale: 0.7;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
</style>
